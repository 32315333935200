import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, HashRouter } from 'react-router-dom';
// import { BatchProvider } from './admin/components/BatchProvider';
// import AdminSidebar from './admin/components/AdminSidebar';
// import Signin from './admin/components/Signin';

const App = React.lazy(() => import('./App'));

const root = ReactDOM.createRoot(document.getElementById('root'));
const isloggin = localStorage.getItem('adminId')

root.render(
  <React.StrictMode>
    <HashRouter>
      <Suspense fallback={<div> Please Wait... </div>} >
        <App />
      </Suspense>
      {/* <BatchProvider>
        {isloggin ? <AdminSidebar /> : <Signin />}
      </BatchProvider> */}
    </HashRouter>
  </React.StrictMode>
);

